import styled from "styled-components";

import {
    Theme
} from "../globalStyles";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${Theme.background};
`;

export const PageHeader404 = styled.div`
    width: 100vw;
    height: 120px;
    background-color: ${Theme.background};
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 90px;
    padding-right: 90px;
    svg{
        width: 210px;
        height: 75px;
    }
    a{
        color: ${Theme.accent1};
        font-size: 20px;
        font-weight: 700;
        text-decoration-line: none;
        transition: 0.3s;
        ::after{
            display: block;
            content: "";
            width: 0px;
            height: 3px;
            background-color: ${Theme.accent1};
            transition: 0.3s;
        }
        :hover{
            opacity: 0.8;
            ::after{
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 500px){
        padding-left: 30px;
        padding-right: 30px;
    }
`;

export const ErrorMessageContainer = styled.div`
    width: 100vw;
    height: calc(100vh - 120px - 110px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    sub{
        width: 300px;
        height: 300px;
    }
    title{
        display: block;
        font-size: 25px;
        font-weight: 800;
        color: ${Theme.accent1};
    }
    span{
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: ${Theme.accent1};
    }
`;

export const Page404Footer = styled.div`
    width: 100vw;
    height: 110px;
    background-color: ${Theme.accent2};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    sub{
        width: fit-content;
        height: fit-content;
        display: block;
        font-size: 16px;
        font-weight: 300;
        color: ${Theme.accent1};
        margin-top: 2.5px;
        margin-bottom: 2.5px;
    }
    a{
        color: ${Theme.accent1};
        opacity: 0.6;
        ::after{
            display: block;
            content: "";
            width: 0px;
            height: 3px;
            background-color: ${Theme.accent1};
            transition: 0.3s;
        }
        :hover{
            opacity: 0.4;
            ::after{
                width: 100%;
            }
        }
    }
`;