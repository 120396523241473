import React from "react";
import Lottie from 'react-lottie';

import {
    Container,
    PageHeader404,
    ErrorMessageContainer,
    Page404Footer
} from "../styles/404";

import Logo from "../images/Commons/logo.svg";

import * as animationData from "../lotties/Not_found.json";

class Page404 extends React.Component{
    render(){    
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <>
                <Container>
                    <PageHeader404>
                        <Logo />
                        <a href="/">
                            HOME
                        </a>
                    </PageHeader404>
                    <ErrorMessageContainer>
                        <sub>
                            <Lottie options={defaultOptions}
                                height={300}
                                width={300}/>
                        </sub>
                        <title>404</title>
                        <span>
                            Oups! Desculpe, página não encontrada!
                        </span>
                    </ErrorMessageContainer>
                    <Page404Footer> 
                        <sub>
                            Direitos autorais de Victor Rosa Gomez
                        </sub>
                        <sub>
                            Esse site usa ícones do
                            <a href="https://www.linkedin.com/in/victor-rosa-gomez-15953a171/"> ICONS8</a>
                        </sub>
                        <sub>
                            Animação do site
                            <a href="https://lottiefiles.com/5189-page-missing#"> Lottie Files</a>
                        </sub>
                    </Page404Footer>
                </Container>
            </>
        );
    }
}

export default Page404;